/* eslint-disable */
<template>
  <div>
    <nav class="navbar navbar-expand-md navbar-light bg-light">
      <img src="../../assets/logos/Logopos.png" style="width:250px;height:30px;" />
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a class="nav-link" href="https://inventory.lynxlook.com" target="_blank">Dashboard</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" @click="openModal()">Chercher une
              commande</a>
              <ModalCommande :showModal="showModal" @modal-close="handleCloseModal"></ModalCommande>
          </li>
          
          <li class="nav-item">
            <a class="nav-link btn btn-danger" @click="Logout()">LOGOUT</a>
          </li>
        </ul>
        <form class="form-inline my-2 my-lg-0" hidden>
          <select class="form-control mr-sm-2" v-model="order_action" @change="change_order_action()">
            <option value="create">Nouvelle commande</option>
            <option value="edite">Modifier une commande</option>
          </select>
        </form>
      </div>
    </nav>
    <div class="row mx-3 text-center">
      <div class="card col-sm shadow-sm mt-2">
        <div class="card-header">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="entered_by" id="QR_CODE" value="QR Code">
            <label class="form-check-label" for="QR_CODE">QR Code</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" v-model="entered_by" id="Manuel" value="Manuel">
            <label class="form-check-label" for="Manuel">Manuel</label>
          </div>
          <div class="form-check form-check-inline">
            <button type="button" class="btn btn-dark btn-lg" @click="fetchCurrentProducts()">Actualiser </button>
          </div>

        </div>
        <div class="card-body anyClass">
          <div :class="product.totalQty > 0 ? 'card mb-3 border-success' : 'card mb-3 border-danger'"
            @click="product.totalQty > 0 ? addToCart(product) : ruptStk()" v-for="product in filtredProducts"
            :key="product.id" style="max-width: 540px; border-width: thick;">
            <div class="row no-gutters">
              <div class="col-md-4 m-1">
                <img :src="storageURl+'/' + product.picture" style="width:100px;height:100px;" />
              </div>
              <div class="col-md">
                <div class="card-body">
                  <h5 class="card-title" >Name : <b v-text="product.name"></b></h5>
                  <h5 class="card-title" >Code : <b v-text="product.code"></b></h5>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">Prix : <b v-text="product.prix"></b> DH</li>
                    <li class="list-group-item">En stock : <b v-text="product.totalQty > 0 ? 'oui' : 'non'"></b> -
                      Qty : <b v-text="product.totalQty"></b>
                      
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class=" col-sm-8 mx-2">

        <div class="row">
          <div class="col-sm-6">
            <div class="card shadow-sm mt-2">
              <div class="card-body">
                <div class="form-row align-items-center mb-2">
                  <div class="col">
                    <label class="sr-only" for="inlineFormInput">code Produit</label>
                    <input type="text" class="form-control " :disabled="entered_by != 'QR Code'" v-model="search"
                      id="inlineFormInput" placeholder="code Produit" autofocus>
                  </div>
                </div>
                <div class="form-row" v-if="entered_by == 'Manuel'">
                  <div class="btn-group col-12" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-primary btn-lg" @click="search += 0">0</button>
                    <button type="button" class="btn btn-primary btn-lg ml-2" @click="search += 1">1</button>
                    <button type="button" class="btn btn-primary btn-lg mx-2" @click="search += 2">2</button>
                    <button type="button" class="btn btn-primary btn-lg mr-2" @click="search += 3">3</button>
                    <button type="button" class="btn btn-primary btn-lg" @click="search += 4">4</button>

                  </div>
                  <div class="btn-group col-12 my-2" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-primary btn-lg" @click="search += 5">5</button>
                    <button type="button" class="btn btn-primary btn-lg ml-2" @click="search += 6">6</button>
                    <button type="button" class="btn btn-primary btn-lg mx-2" @click="search += 7">7</button>
                    <button type="button" class="btn btn-primary btn-lg mr-2" @click="search += 8">8</button>
                    <button type="button" class="btn btn-primary btn-lg" @click="search += 9">9</button>

                  </div>
                  <div class="btn-group col-12" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-danger btn-lg" @click="search = ''">Vider</button>
                    <button type="button" class="btn btn-primary btn-lg ml-2" @click="search += 'M'">M</button>
                    <button type="button" class="btn btn-primary btn-lg mx-2" @click="search += 'F'">F</button>
                    <button type="button" class="btn btn-primary btn-lg mx-2" @click="search += 'O'">O</button>
                    <button type="button" class="btn btn-primary btn-lg mr-2" @click="search += 'U'">U</button>
                    <button type="button" class="btn btn-warning btn-lg"
                      @click="search = search.substr(0, search.length - 1)">Corriger</button>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="card shadow-sm mt-2">
              <div class="card-body">
                <div class="form-row justify-content-center">
                  <div class="col-sm-12">
                    <h4>Total à payer : </h4>
                    <h3> <span v-text="total_price"></span> DH</h3>


                  </div>
                  <div class="col-sm-8">
                    <button type="button" class="btn btn-success btn-lg btn-block "
                      @click="passerCommande(userData?.user?.id)">Payer</button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card shadow-sm mt-2" style="height: 70vh;">
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">Panier</h4>
          </div>
          <div class="card-body anyClass">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Nom du produit</th>
                    <th>prix</th>
                    <th>Quantité</th>
                    <th>Total de la ligne</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(cartProduct, index) in panier" :key="index">
                    <td class="align-middle"><span v-text="cartProduct.code"></span>
                    </td>
                    <td class="align-middle"><span v-text="cartProduct.prix"></span> MAD</td>
                    <td class="align-middle">
                      <button type="button" class="btn btn-success btn-lg rounded-circle"
                        @click="cartProduct.qty < cartProduct.stock_limit ? cartProduct.qty++ : depassement()">
                        +
                      </button>
                      <b><span class="mx-4" v-text="cartProduct.qty"></span></b>
                      <button type="button" class="btn btn-danger btn-lg rounded-circle"
                        @click="cartProduct.qty > 1 ? cartProduct.qty-- : panier.splice(index, 1)">
                        -
                      </button>
                    </td>
                    <td class="align-middle"> <span v-text="cartProduct.unit_price * cartProduct.qty"></span> MAD</td>
                    <td class="align-middle">
                      <button type="button" class="btn btn-dark" @click="panier.splice(index, 1)">
                        Supprimer
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>


      </div>

    </div>

  </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
import Swal from 'sweetalert2'
import { ref } from "vue";
import ModalCommande from "./ModalCommande.vue";
import { API_URL,PDF_URL,STORAGE_URL } from '../../services/apis';

export default {
  name: 'PosPage',
  components: {
    ModalCommande,
  },
  data() {
    return {
      products: [],
      editedPrducts: [],
      search: "",
      panier: [],
      orderCodeValid: true,
      codeOrder: "",
      order_action: "create",
      entered_by: "QR Code",
      showModal: false,
      closeModal: false,
      userData: [],
      storageURl : STORAGE_URL
    };
  },
  props: ["index_route", "store_order", "osk_route", "bring_order_route"],

  mounted() {
    this.fetchCurrentProducts();
    // this.getBorne();
    this.userData = JSON.parse(localStorage.getItem('userData'))
  },

  computed: {
    filtredProducts: function () {
      return this.products.filter((product) => {
        return product.code.includes(this.search.trim().match(/[a-zA-Z]/g) == true ? this.search.trim().split("|")[0] : this.search.trim());
      });
    },
    total_price: function () {
      let total_price = 0;
      this.panier.forEach((e) => {
        total_price += e.unit_price * e.qty;
      });

      return total_price;
    },
  },

  methods: {
    searchOrder(email) {
      // console.log(email)
      let existingObj = this;
      const config = {
        headers: {
          "content-type": "application/json",
        },
      };
      let data = new FormData();
      this.order_id = this.codeOrder.trim();
      data.append("order_id", this.order_id);
      data.append("customer_email", email);
      axios
        .post(this.bring_order_route, data, config)
        .then(function (res) {
          // console.log(res);
          existingObj.panier = [];
          existingObj.fetchCurrentProducts();

          res.data.forEach((element) => {
            for (let ind = 0; ind < element.qty; ind++) {
              existingObj.addToCart(element.product);
            }
          });
        })
        .catch(function (error) {
          // console.log(error);
        });
    },
    change_order_action() {
      this.panier = [];
    },
    osk() {
      axios.get(this.osk_route);
    },
    fetchCurrentProducts() {
      this.products = [];
      let existingObj = this;
      axios.get(API_URL+'/out/produit/index').then(function (res) {
        //console.log(res.data.data)
        existingObj.products = res.data.data;
      });
    },
    addToCart(product) {
      if (this.panier.some((e) => e.product_id === product.id)) {
        let index = this.panier.findIndex(
          (e) => e.product_id === product.id
        );
        this.panier[index].qty < product.totalQty
          ? this.panier[index].qty++
          : this.depassement();
      } else {
        this.panier.push({
          product_id: product.id,
          code: product.code,
          unit_price: product.prix,
          qty: 1,
          stock_limit: product.totalQty,
          options: [],
          /*contenance: product.contenance_type,
          unit_price: product.price.inCurrentCurrency.amount,
          qty: 1,
          stock_limit: product.qty,
          entered_by: this.entered_by,
          options: [],*/
        });
      }
    },
    ruptStk() {
      Swal.fire('Stock epuisé', "Le produit n'est plus disponible en stock", 'error')
    },
    depassement() {
      Swal.fire('Stock epuisé', "Vous ne pouvez pas ajouter plus de quantité pour ce produit", 'error')
    },
    passerCommande(userId) {
      //console.log(this.panier)
      //console.log(this.order_action)
      if (this.panier.length == 0) {
        Swal.fire('Attention', "le panier est vide", 'error')
      } else {
        let existingObj = this;
        const config = {
          headers: {
            "content-type": "application/json",
          },
        };
        let data = new FormData();
        data.append("panier", JSON.stringify(this.panier));
        data.append("userId", userId);
        data.append("order_action", this.order_action);
        data.append("order_id", this.order_id);
        axios
          .post(API_URL+'/out/commande/store', data, config)
          .then(function (res) {
            //console.log(res);
            Swal.fire('Done', "la commande est effectué avec succès", 'success')
            existingObj.printDiv(res.data.data)
            existingObj.panier = [];
            existingObj.fetchCurrentProducts();
          })
          .catch(function (error) {
            // console.log(error);
          });
      }
    },
    printDiv(link) {
      console.log('in print');
      console.log(link);

      // Assuming your application runs on http://127.0.0.1:8000
      const fullPath = PDF_URL+`${link}`;

      // Open the PDF in a new tab
      window.open(fullPath, '_blank');
    },
    openModal() {
      this.showModal = true;
    },
    handleCloseModal() {
      this.showModal = false;
    },
    Logout(){
      localStorage.setItem('isConnected','0');
      localStorage.setItem('userData','null');
      localStorage.setItem('token','null');
      this.$router.push({ name: 'Login' })

    }
  },
};

</script>

<style>
body {
  font-family: 'Nunito', sans-serif;
  overflow: hidden;
}

.col-sm,
.col-sm-8 {
  padding-right: 0px;
  padding-left: 0px;
}

.anyClass {
  height: 150px;
  overflow-y: scroll;
}

/* Style the modal */
.modal {
  display: block;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  /* Could be more or less, depending on screen size */
}

/* Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
