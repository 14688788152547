import { createRouter, createWebHistory } from 'vue-router'
import LoginPage from '../components/auth/LoginPage.vue'
import PosPage from '@/components/pos/PosPage.vue'
const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: PosPage,
    meta: { requiresAuth: true } // Add meta field to indicate this route requires authentication
  }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    const isLoggedIn = localStorage.getItem('isConnected') === '1'
    if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {
      next({ name: 'LoginPage' })
    } else {
      next()
    }
})

export default router
