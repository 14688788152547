<template>
  <div v-if="showModal" class="modal fade show" tabindex="-1" style="display: block;">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <slot name="header">Annuler une commande</slot>
          <button type="button" class="close" @click="closeModal">
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <slot name="content">
            <div class="form-group">
              <label for="codeInput">Enter code</label>
              <input v-model="inputValue" id="codeInput" class="form-control" placeholder="Enter code" />
            </div>
            <button class="btn btn-primary" @click="fetchData">Chercher</button>
            <div v-if="commande" class="mt-5">
              <span class="mr-3"><strong>Ref:</strong> {{ commande.ref }}</span>
              <button class="btn btn-danger ml-3" @click="deleteData(commande.ref)">Supprimer</button>
            </div>
          </slot>
        </div>
        <div class="modal-footer">
          <slot name="footer">
            <button class="btn btn-secondary" @click="closeModal">Fermer</button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import { API_URL } from '@/services/apis';

export default {
  name: "ModalCommande",
  props: {
    showModal: Boolean,
  },
  data() {
    return {
      inputValue: '',
      commande: null,
    };
  },
  methods: {
    closeModal() {
      this.$emit('modal-close');
    },
    fetchData() {
      let existingObj = this;
      axios.get(API_URL+`/out/commande/${this.inputValue}`).then(function (res) {
        //console.log(res.data.data)
        if(res.data.data){
        existingObj.commande = res.data.data;
        }else{
          alert('le code erroné Ou bien cette commande est déja annuler :)')
        }
      });
    },
    deleteData(id) {
      
      let existingObj = this;
      axios.post(API_URL+`/out/commande/delete/${id}`).then(function (res) {
        console.log(res.data.data)
        existingObj.commande = null;
        Swal.fire('Deleted', 'The data has been deleted.', 'success');
      });
    },
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.modal-container {
  width: 300px;
  background-color: #fff;
  padding: 20px;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
}
</style>
