<!-- src/components/auth/LoginPage.vue -->
<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card mt-5">
          <div class="card-header">
            <h3 class="text-center">Login</h3>
          </div>
          <div class="card-body">
            <span v-if="error" style="color:red;">Email or Password Wrong!</span>
            <form @submit.prevent="login">
              <div class="mb-3">
                <label for="email" class="form-label">Email address</label>
                <select class="form-select" v-model="email" required>
                  <option v-for="email in emails" :key="email.email" :value="email.email">{{ email.email }}</option>
                </select>
              </div>
              <div class="mb-3">
                <label for="password" class="form-label">Password</label>
                <input type="password" class="form-control" id="password" v-model="password" required>
              </div>
              <button type="submit" class="btn btn-primary w-100">Login</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { useRouter } from 'vue-router'
import { API_URL } from '@/services/apis'

export default {
  name: 'LoginPage',
  data() {
    return {
      email: '',
      password: '',
      error:false,
      emails:[]
    }
  },
  setup() {
    const router = useRouter()
    return { router }
  },
  created(){
    this.getEmailVendors();
  },
  methods: {
    async login() {
      try {
        const response = await axios.post(API_URL+'/login/caissier', {
          email: this.email,
          password: this.password
        })

        if (response.data.success) {
          localStorage.setItem('userData', JSON.stringify(response.data.data))
          localStorage.setItem('isConnected', '1')
          localStorage.setItem('token', response.data.data.token)

          this.$router.push({ name: 'Dashboard' }) // Ensure this router method is called correctly
        } else {
          localStorage.setItem('userData', JSON.stringify(response.data.data))
          localStorage.setItem('isConnected', '0')
          localStorage.setItem('token', '')
          // Handle login failure
        }
      } catch (error) {
        if(error.response.data.data.error){
          this.error = true;
        }
        localStorage.setItem('userData', JSON.stringify({}))
        localStorage.setItem('isConnected', '0')
        localStorage.setItem('token', '')
        console.error('An error occurred:', error.response.data.data.error)
      }
    },
    async getEmailVendors(){
      try {
        const response = await axios.get(API_URL+'/login/email/vendors')
        console.log(response.data.data)
        this.emails = response.data.data
      } catch (error) {
        if(error.response.data.data.error){
          this.error = true;
        }
      }
    }
  }
}
</script>

<style>
/* Add your custom styles here */
</style>
